/*******************************
         Site Overrides
*******************************/

.ui.form {
  margin: 1rem 0;
}

.ui.form.field {
  float: top;
  
}

.ui.form .fields > .field{
  width: 100%;
  padding: 7px;
}

.searchbar{
  margin: 0 0.5rem;
}