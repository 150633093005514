/*******************************
         Site Overrides
*******************************/

.ui.button.relaxed {
  margin: 1rem 1rem 1rem 0;
}

.ui.button.create-review-button {
  font-size: 1.25rem;
}

.review-button{
  min-width: 12rem;
}

@media screen and (max-width: 769px){
  .review-button{
    display: none !important;
  }
}

.ui.button.quote {
  background: @white;
}

.ui.button.submitQuote {
  background-color: #4071CA;
  padding: 1rem;
  color: @white;
  float: right;
  margin-bottom: 1rem;
}

.ui.button.closeQuote {
  background-color: #F89344;
  padding: 1rem;
  color: @darkNavy;
  float: right;
  border-radius: 0.7rem;
  margin: 1rem;
}

.ui.button.submitSyllabus{
  background-color: #4071CA;
  padding: 1rem;
  color: @white;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.ui.button.closeSyllabus{
  background-color: #F89344;
  padding: 1rem;
  color: @darkNavy;
  float: right;
  border-radius: 0.7rem;
  margin: 1rem;
  margin-top: 3rem;
}