/*******************************
         Site Overrides
*******************************/

.ui.container.root-container {
  margin: 20vh 0;
  min-height: 70vh;
}

.ui.container.root-container-mobile {
  margin: 15vh 0;
  min-height: 50vh;
  padding-top: 0rem;
}

.ui.container.course-card {
  background-color: @gray;
  padding: 10px 25px;
}