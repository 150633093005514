/*******************************
         Site Overrides
*******************************/

p {
  margin: 0;
}

.add-margin {
  margin: 1rem 0;
}

/*-----------------------------
            Footer
-----------------------------*/

.footer-link {
  color: @white;
  font-size: 1rem;
  font-weight: @bold;
}

/*-----------------------------
        Professor Link
-----------------------------*/

.professor-link {
  margin-right: 0.5em;
}

.list-item {
  margin: 0.7rem 0;
  font-weight: 600; 
}