/*******************************
         Site Overrides
*******************************/

.ui.grid.add-margin {
  margin: 2rem 0;
}

.ui.grid.fluid {
  width: 100%;
}

/*-----------------------------
            Footer
-----------------------------*/

.ui.grid.footer {
  background-color: @darkNavy;
  font-family: "Source Code Pro", Arial, Helvetica, sans-serif;
  min-height: fit-content;
}

/*-----------------------------
          ReviewCard
-----------------------------*/

.ui.grid > .column.review-card-left-column {
  background-color: @gray;
  padding: 25px;
  width: 90%;
}

.ui.grid > .column.review-card-right-column {
  background-color: @navy;
  padding-top: 25px;
  width: 10%;
}

/*-----------------------------
          NewestReviewCard
-----------------------------*/

.ui.grid > .column.newest-review-card {
  background-color: @gray;
  padding: 25px;
  width: 100%;
}

/*-----------------------------
        VotesContainer
-----------------------------*/

.ui.grid > .row.votes-container-icon-row {
  padding-bottom: 0;
}

.ui.grid > .row.votes-container-number-row {
  font-weight: @bold;
  color: white;
}
