/*******************************
         Site Overrides
*******************************/

.ui.header {
  display: inline-block;
}

.ui.header.no-margin {
  margin: 0 0.5em 0 0;
}

.ui.header.culpa-description {
  font-weight: normal;
}

.ui.header.nugget-description {
  color: @darkNavy;
  margin-left: 1rem;
}

.ui.header.review-card-header {
  color: @linkColor;
} 

@media screen and (max-width: 769px){
  .ui.header.culpa-description {
    font-size: 1.35rem;
  }
  .ui.header.nugget-description {
    margin-left: 0.5rem;
    font-size:1rem;
  }
}