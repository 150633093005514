/*******************************
         Site Overrides
*******************************/

.ui.modal > .content {
  color: @white;
}

.ui.modal.submit-quote{
  background: @white;
}

.ui.modal.submit-quote > .header{
  background: @white;
  color: @darkNavy;
  display: flex; /* Ensure flexbox layout */
  justify-content: space-between; /* Align items to the start and end of the container */
  align-items: center; /* Center items vertically */
  font-size: 1.5rem;
}

.ui.modal.submit-quote > .content{
  background: @white;
}

.ui.modal.submit-quote > .actions{
  background: @white;
}

.ui.modal.thank-you-modal > .header{
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.ui.modal.thank-you-modal > .content{
  font-size: 1.1rem;
}